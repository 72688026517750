import React from "react"
import Layout from "../components/layout"
import Seo from "../components/SEO"

export default () => {
  return (
    <Layout>
      <Seo />
      <h1>Food</h1>
      <p>We discovered pretty early on in our relationship that we both have an inexplicable and inexhaustible love for breakfast food.</p>
      <p>Whether at home or during our travels together, some of the most intense and formative moments in our relationship have been over breakfast food (although not necessarily during what may be considered “breakfast time”).</p>
      <p>In recognition of that fact, we’d like to enjoy what we commonly refer to as “Breakfast for Dinner” with our friends as family.</p>
      <h2>Menu</h2>
      <h3>Appetizers</h3>
      <ul className="chevron-list">
        <li>Individualized Veggie Cups</li>
        <li>Fruit Skewers</li>
      </ul>
      <h3>Dinner</h3>
      <p>There will be a food truck with the following selection:</p>
      <ul className="chevron-list">
        <li>Vegetable Fritatta (or Tofu Bake for those with dietary restrictions)</li>
        <li>Spinach, Tomato, Fritatta (feta on the side)</li>
        <li>Bacon &amp; Sausage</li>
        <li>Rosemary Home Fries with Sea Salt, Coarse Pepper &amp; Ketchup</li>
        <li>Waffle Station:</li>
        <ul>
          <li>Syrup</li>
          <li>Berries</li>
          <li>Whipped Cream</li>
          <li>Nutella</li>
        </ul>
      </ul>
    </Layout>
  )
}

